<template>
  <div>
    <b-card
      title="编辑流程"
      class="statement-card"
    >
      <el-steps
        :active="2"
        align-center
      >
        <el-step
          title="1.起草声明"
          icon="el-icon-edit"
        />
        <el-step
          title="2.声明预览"
          icon="el-icon-document"
        />
        <el-step
          title="3.声明发布"
          icon="el-icon-upload"
        />
      </el-steps>
    </b-card>
    <b-card title="评估信息">
      <el-form
        ref="form1"
        :model="form"
        label-width="80px"
      >
        <b-row>
          <b-col md="3">
            <el-form-item
              label="名称"
              prop="title"
              :rules="[ { required: true, message: '名称不能为空', trigger: 'change' }]"
            >
              <el-input v-model="form.title" />
            </el-form-item>
          </b-col>
          <b-col md="3">
            <el-form-item
              label="版本"
              prop="version"
              :rules="[ { required: true, message: '版本不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.version"
                v-input-filter
              />
            </el-form-item>
          </b-col>
          <b-col md="3">
            <el-form-item
              label="业务名称"
              prop="productIdList"
              :rules="[{ required: true, message: '请选择产品', trigger: 'change' }]"
            >
              <el-select
                v-model="form.productIdList"
                multiple
              >
                <el-option
                  v-for="(item,index) in productOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>
      </el-form>
      <div
        v-show="form.templateOptionsList.length === 0"
        class="dialog-footer text-center"
      >
        <el-button
          size="small"
          @click="back"
        >
          返 回
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSubmit"
        >
          下 一 步
        </el-button>
      </div>
    </b-card>
    <b-card
      v-show="form.templateOptionsList.length > 0"
      title="模版选项"
    >
      <el-form
        ref="form2"
        :label-position="'top'"
        label-width="120px"
      >
        <b-row>
          <b-col
            v-for="(item,index) in form.templateOptionsList"
            :key="index"
            md="3"
          >
            <el-form-item
              :label="item.optionsName"
            >
              <el-radio-group v-model="item.isExist">
                <el-radio :label="true">
                  是
                </el-radio>
                <el-radio :label="false">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </b-col>
        </b-row>
      </el-form>
      <div
        class="dialog-footer text-center"
      >
        <el-button
          size="small"
          @click="back"
        >
          返 回
        </el-button>
        <el-button
          type="primary"
          size="small"
          @click="onSubmit"
        >
          下 一 步
        </el-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  ListTemplateOptions, CreateByTemplate, GetTemplateContentByOptions, GetTempCreateId,
} from '@/api/enterprise/statement'
import { error, success } from '@core/utils/utils'
import { getAllProduct } from '@/api/enterprise/product'

export default {
  data() {
    return {
      active: 1,
      loading: false,
      titleObj: {
        1: '生成使用属性',
        2: '生成基础内容信息',
      },
      form: {
        templateCode: this.$route.query.id,
        templateOptionsList: [],
        productIdList: [],
        title: this.$route.query.title,
        version: this.$route.query.version,
      },
      productOptions: [],
    }
  },
  created() {
    if (this.$route.query.productIdList && this.$route.query.productIdList.length > 0) {
      const pidList = []
      this.$route.query.productIdList.forEach(pid => pidList.push(Number(pid)))
      this.form.productIdList = pidList
    }
    this.listTemplateOptions()
    this.getAllProduct()
  },
  mounted() {
    const navItems = document.getElementsByClassName('nav-item')
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === '隐私声明管理') {
          navTag.className = 'nav-item active'
          navTag.parentNode.style = 'display: block'
        }
      }
    })
  },
  destroyed() {
    const navItems = document.getElementsByClassName('nav-item')
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === '隐私声明管理') {
          if (this.$route.path === '/statement') {
            navTag.className = 'nav-item active'
          } else {
            navTag.className = 'nav-item'
          }
        }
      }
    })
  },
  methods: {
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    back() {
      this.$router.push({
        path: '/statement',
      })
    },
    listTemplateOptions() {
      ListTemplateOptions(this.form.templateCode).then(res => {
        res.data.data.forEach(r => {
          const item = {
            id: r.id,
            isExist: true,
            templateCode: this.form.templateCode,
            optionsName: r.optionsName,
          }
          this.form.templateOptionsList.push(item)
        })
        if (this.form.templateOptionsList.length === 0) {
        }
      })
    },
    onSubmit() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.loading = true
          GetTempCreateId(this.form).then(res => {
            const resData = res.data
            this.loading = false
            if (resData.code === 0) {
              success(resData.msg)
              this.$router.push({ path: '/statement-editor', query: { lId: resData.data, tId: this.$route.query.id } })
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
 .statement-card{
   .el-step__title.is-finish, .el-step__head.is-finish {
      color: #0d40b2 !important;
     border-color: #0d40b2 !important;
   }
 }
 .statement-upload-btn{
   position: absolute;
   right: 20px;
   top: 10px;
 }
</style>
