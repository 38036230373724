import { get, post } from '@/libs/axios'

export const getStatementListByPage = params => post('/enterprise/statement/GetStatementListByPage', params)
export const UpdateReleaseStatus = params => post('/privacy/statement/UpdateReleaseStatus', params)
export const getStatementHistoryVersion = () => get('/enterprise/statement/GetStatementHistoryVersion')
export const GetStatementSatisfaction = () => get('/privacy/statement/GetStatementSatisfaction')
export const GetTemplateCount = () => get('/privacy/statement/GetTemplateCount')
export const ListTemplate = () => get('/privacy/statement/ListTemplate')
export const GetAreaList = () => get('/privacy/statement/GetAreaList')
export const QueryHistoryOptions = () => get('/privacy/statement/QueryHistoryOptions')
export const getStatementById = id => post('/enterprise/statement/GetStatementById', id)
export const statementDel = id => post('/privacy/statement/Delete', id)
export const DeleteRelease = id => post('/privacy/statement/DeleteRelease', id)
export const GetTemplateOptions = code => get(`/privacy/statement/template/options?code=${code}`)
export const PreviewByContent = id => get(`/privacy/statement/version/preview?id=${id}`)
export const GetDetailById = id => get(`/privacy/statement/GetDetailById?id=${id}`)
export const QueryTemplateById = id => get(`/privacy/statement/QueryTemplateById?id=${id}`)
export const IntelligentImport = id => get('/privacy/statement/IntelligentImport', id)
export const ShowStatementById = id => post('/enterprise/statement/ShowStatementById', id)
export const getStatementHistoryById = id => post('/enterprise/statement/GetStatementHistoryById', id)
export const getStatemetPublishById = id => post('/enterprise/statement/GetStatementPublishById', id)
export const getStatementPublishListByPage = params => post('/privacy/statement/ReleasePage', params)
export const CreateByTemplate = params => post('/privacy/statement/CreateByTemplate', params)
export const StatementPage = params => post('/privacy/statement/Page', params)
export const SaveTemplate = params => post('/privacy/statement/SaveTemplate', params)
export const createStatementPublish = params => post('/enterprise/statement/CreateStatementPublish', params)
export const updateStatementPublishStatus = params => post('/privacy/statement/UpdateReleaseStatus', params)
export const getStatementHistoryByVersion = params => post('/enterprise/statement/GetStatementHistoryByVersion', params)
export const GetTemplateContentByOptions = id => get(`/privacy/statement/GetTemplateContentByOptions?id=${id}`)
export const GetTempCreateId = params => post('/privacy/statement/GetTempCreateId', params)

//

export const saveOrUpdateStatement = params => {
  const submitUrl = params.id ? '/privacy/statement/UpdateRelease' : '/privacy/statement/CreateRelease'
  return post(submitUrl, params)
}
